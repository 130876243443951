import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { FiPlusCircle } from 'react-icons/fi';
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/Navbar';
import api from '../../services/api';
import ModalAddJob from '../../components/ModalAddJob';

interface Job {
  id: string;
  name: string;
  description: string;
  salary: string;
  startTime: string;
  endTime: string;
}

interface ICreateJob {
  name: string;
  salary: string;
  description: string;
  startTime: string;
  endTime: string;
}

const Jobs: React.FC = () => {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    api.get('/jobs').then(response => {
      setJobs(response.data);
    });
  }, []);

  async function handleAddJob(newJob: ICreateJob): Promise<void> {
    const response = await api.post('/jobs', newJob);

    const newJobRegistered = response.data;

    setJobs([...jobs, newJobRegistered]);
  }

  function toggleModel(): void {
    setModalOpen(!modalOpen);
  }

  return (
    <>
      <ModalAddJob
        isOpen={modalOpen}
        setIsOpen={toggleModel}
        handleAddJob={handleAddJob}
      />
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <Navbar />
          <div id="content">
            <div className="container-fluid">
              {!modalOpen && (
                <>
                  <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Vagas</h1>
                    <Link
                      type="button"
                      to="/nova-vaga"
                      className="d-none d-sm-inline-block btn btn-sm btn-primary btn-success shadow-sm p-2"
                    >
                      <FiPlusCircle className="fas fa-download fa-sm mr-2" />
                      Nova vaga
                    </Link>
                  </div>
                  <p className="mb-4">Veja todas as vagas cadastradas</p>
                  <div className="card card-bleed shadow-light-lg">
                    <div className="card-header">
                      <h4 className="mb-0">Vagas cadastradas</h4>
                    </div>
                    <div className="card-body">
                      <div className="list-group list-group-flush">
                        {jobs.map(job => (
                          <div className="list-group-item">
                            <div className="row align-items-center">
                              <div className="col">
                                <p className="mb-0">
                                  <Link to={`/vaga/${job.id}`}>{job.name}</Link>
                                </p>

                                <small className="text-gray-700">
                                  {job.salary}
                                </small>
                              </div>
                              <div className="col-auto">
                                <Link
                                  type="button"
                                  to={`/editar-vaga/${job.id}`}
                                  className="d-none d-sm-inline-block btn btn-sm btn-primary btn-success shadow-sm p-2"
                                >
                                  <span className="h6 text-uppercase fw-bold">
                                    Editar
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Jobs;
