import React, { useCallback, useEffect, useState } from 'react';

import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import { FiXCircle } from 'react-icons/fi';
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/Navbar';
import api from '../../services/api';
import Avatar from '../../assets/img/illustrations/defaultAvatar.png';

interface User {
  id: string;
  name: string;
  email: string;
  avatar: string;
  avatar_url: string;
  NO: number;
  SO: number;
  NE: number;
  SE: number;
  linkedin: string;
}

interface Job {
  id: string;
  name: string;
  description: string;
  salary: string;
  startTime: string;
  endTime: string;
}

interface JobParams {
  job: string;
}

const JobDetail: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const history = useHistory();
  const [isAllSelect, setIsAllSelect] = useState(true);
  const [isNOSelect, setIsNOSelect] = useState(false);
  const [isNESelect, setIsNESelect] = useState(false);
  const [isSOSelect, setIsSOSelect] = useState(false);
  const [isSESelect, setIsSESelect] = useState(false);
  const [job, setJob] = useState<Job>();
  const { params } = useRouteMatch<JobParams>();

  const getUsers = useCallback(() => {
    api.get(`/subscriptions/users/${params.job}`).then(response => {
      setUsers(response.data);
    });
  }, [params]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  useEffect(() => {
    api.get(`/jobs/${params.job}`).then(response => {
      setJob(response.data);
    });
  }, [params]);

  async function handleDeleteJob(): Promise<void> {
    await api.delete(`/jobs/${params.job}`);

    history.push('/');
  }

  const handleSelectAll = useCallback(() => {
    setIsAllSelect(true);
    setIsNOSelect(false);
    setIsNESelect(false);
    setIsSESelect(false);
    setIsSOSelect(false);

    getUsers();

    setUsers(users);
  }, [getUsers, users]);

  const handleSelectNO = useCallback(async () => {
    setIsNOSelect(true);
    setIsAllSelect(false);
    setIsNESelect(false);
    setIsSESelect(false);
    setIsSOSelect(false);

    const allUsers = await api.get(`/subscriptions/users/${params.job}`);

    const noUsers = allUsers.data.filter(
      (user: { NO: any; SE: any; NE: any; SO: any }) => {
        return (
          Number(user.NO) > Number(user.SE) &&
          Number(user.NO) > Number(user.NE) &&
          Number(user.NO) > Number(user.SO)
        );
      },
    );

    if (noUsers) {
      setUsers(noUsers);
    }
  }, [params.job]);

  const handleSelectNE = useCallback(async () => {
    setIsNESelect(true);
    setIsNOSelect(false);
    setIsAllSelect(false);
    setIsSESelect(false);
    setIsSOSelect(false);

    const allUsers = await api.get(`/subscriptions/users/${params.job}`);

    const neUsers = allUsers.data.filter(
      (user: { NE: any; SE: any; NO: any; SO: any }) => {
        return (
          Number(user.NE) > Number(user.SE) &&
          Number(user.NE) > Number(user.NO) &&
          Number(user.NE) > Number(user.SO)
        );
      },
    );

    if (neUsers) {
      setUsers(neUsers);
    }
  }, [params.job]);

  const handleSelectSO = useCallback(async () => {
    setIsSOSelect(true);
    setIsNESelect(false);
    setIsNOSelect(false);
    setIsAllSelect(false);
    setIsSESelect(false);

    const allUsers = await api.get(`/subscriptions/users/${params.job}`);

    const soUsers = allUsers.data.filter(
      (user: { SO: any; SE: any; NO: any; NE: any }) => {
        return (
          Number(user.SO) > Number(user.SE) &&
          Number(user.SO) > Number(user.NO) &&
          Number(user.SO) > Number(user.NE)
        );
      },
    );

    if (soUsers) {
      setUsers(soUsers);
    }
  }, [params.job]);

  const handleSelectSE = useCallback(async () => {
    setIsSESelect(true);
    setIsSOSelect(false);
    setIsNESelect(false);
    setIsNOSelect(false);
    setIsAllSelect(false);

    const allUsers = await api.get(`/subscriptions/users/${params.job}`);

    const seUsers = allUsers.data.filter(
      (user: { SE: any; SO: any; NO: any; NE: any }) => {
        return (
          Number(user.SE) > Number(user.SO) &&
          Number(user.SE) > Number(user.NO) &&
          Number(user.SE) > Number(user.NE)
        );
      },
    );

    if (seUsers) {
      setUsers(seUsers);
    }
  }, [params.job]);

  return (
    <div id="wrapper">
      <Sidebar />
      <div id="content-wrapper" className="d-flex flex-column">
        <Navbar />
        <div id="content">
          <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-0 text-gray-800">{job?.name}</h1>
              <button
                type="button"
                onClick={handleDeleteJob}
                className="d-none d-sm-inline-block btn btn-sm btn-primary btn-danger shadow-sm p-2"
              >
                <FiXCircle className="fas fa-download fa-sm mr-2" />
                Deletar vaga
              </button>
            </div>
            <p className="mb-4">Veja todos os candidatos dessa vaga</p>

            <div className="card card-bleed shadow-light-lg">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col-auto">
                    <h4 className="mb-0">
                      {isAllSelect ? (
                        <button
                          type="button"
                          style={{
                            color: '#4e73df',
                            border: 'none',
                            background: '#fff',
                          }}
                        >
                          Candidatos
                        </button>
                      ) : (
                        <button
                          onClick={handleSelectAll}
                          type="button"
                          style={{
                            border: 'none',
                            background: '#fff',
                          }}
                        >
                          Candidatos
                        </button>
                      )}
                    </h4>
                  </div>
                  <div className="col-auto">
                    <span className="h4 fw-bold">
                      {isNOSelect ? (
                        <button
                          type="button"
                          style={{
                            color: '#4e73df',
                            border: 'none',
                            background: '#fff',
                          }}
                        >
                          NO
                        </button>
                      ) : (
                        <button
                          onClick={handleSelectNO}
                          type="button"
                          style={{
                            border: 'none',
                            background: '#fff',
                          }}
                        >
                          NO
                        </button>
                      )}
                    </span>
                  </div>
                  <div className="col-auto">
                    <span className="h4 fw-bold">
                      {isNESelect ? (
                        <button
                          type="button"
                          style={{
                            color: '#4e73df',
                            border: 'none',
                            background: '#fff',
                          }}
                        >
                          NE
                        </button>
                      ) : (
                        <button
                          onClick={handleSelectNE}
                          type="button"
                          style={{
                            border: 'none',
                            background: '#fff',
                          }}
                        >
                          NE
                        </button>
                      )}
                    </span>
                  </div>
                  <div className="col-auto">
                    <span className="h4 fw-bold">
                      {isSOSelect ? (
                        <button
                          type="button"
                          style={{
                            color: '#4e73df',
                            border: 'none',
                            background: '#fff',
                          }}
                        >
                          SO
                        </button>
                      ) : (
                        <button
                          onClick={handleSelectSO}
                          type="button"
                          style={{
                            border: 'none',
                            background: '#fff',
                          }}
                        >
                          SO
                        </button>
                      )}
                    </span>
                  </div>
                  <div className="col-auto">
                    <span className="h4 fw-bold">
                      {isSESelect ? (
                        <button
                          type="button"
                          style={{
                            color: '#4e73df',
                            border: 'none',
                            background: '#fff',
                          }}
                        >
                          SE
                        </button>
                      ) : (
                        <button
                          onClick={handleSelectSE}
                          type="button"
                          style={{
                            border: 'none',
                            background: '#fff',
                          }}
                        >
                          SE
                        </button>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="list-group list-group-flush">
                  {users.map(user => (
                    <div className="list-group-item" key={user.id}>
                      <div className="row align-items-center">
                        <div className="col-auto">
                          <div className="avatar avatar-xl">
                            {user.avatar ? (
                              <img
                                className="avatar-img rounded-circle"
                                src={user.avatar_url}
                                alt="..."
                              />
                            ) : (
                              <img
                                className="avatar-img rounded-circle"
                                src={Avatar}
                                alt="..."
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-6 ms-n5">
                          <p className="mb-0">{user.name}</p>

                          <a
                            className="d-block small text-truncate text-gray-700"
                            href={`mailto:${user.email}`}
                          >
                            {user.email}
                          </a>
                        </div>
                        <div className="col-auto ms-auto">
                          <Link to={`/candidato/${user.id}`}>
                            <span>Visualizar</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDetail;
