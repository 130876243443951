import React from 'react';
import '../../assets/css/styles.css';
import { FiLogOut } from 'react-icons/fi';
import { useAuth } from '../../hooks/auth';

const Navbar: React.FC = () => {
  const { signOut } = useAuth();

  return (
    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
      <ul className="navbar-nav ml-auto">
        <li className="nav-item dropdown no-arrow d-sm-none">
          <a
            className="nav-link dropdown-toggle"
            href="/#"
            id="searchDropdown"
            role="button"
            aria-expanded="false"
          >
            <i className="fas fa-search fa-fw" />
          </a>
          <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in">
            <form className="form-inline mr-auto w-100 navbar-search">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control bg-light border-0 small"
                  placeholder="Search for..."
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                />
                <div className="input-group-append">
                  <button className="btn btn-primary" type="button">
                    <i className="fas fa-search fa-sm" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </li>
        <li className="nav-item dropdown no-arrow">
          <button
            className="nav-link btn dropdown-toggle"
            type="button"
            onClick={signOut}
          >
            <span className="mr-2 d-none d-lg-inline text-gray-600 small">
              Sair
            </span>
            <FiLogOut color="#858796" />
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
