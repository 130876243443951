import React from 'react';
import { FaQuestion, FaSuitcase, FaUser } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Sidebar: React.FC = () => {
  return (
    <div id="wrapper">
      <ul
        className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
        id="accordionSidebar"
      >
        <div className="sidebar-brand d-flex align-items-center justify-content-center">
          <div className="sidebar-brand-icon rotate-n-15">
            <i className="fas fa-laugh-wink" />
          </div>
          <div className="sidebar-brand-text mx-3">Recrutalentos</div>
        </div>

        <hr className="sidebar-divider my-0" />

        <li className="nav-item">
          <Link className="nav-link" to="/perfil">
            <FaUser className="fas fa-fw fa-tachometer-alt mr-3" />
            <span>Perfil</span>
          </Link>
        </li>

        <hr className="sidebar-divider" />

        <div className="sidebar-heading">Vagas</div>

        <li className="nav-item active">
          <Link className="nav-link" to="/vagas">
            <FaSuitcase className="fas fa-fw fa-tachometer-alt mr-3" />
            <span>Vagas</span>
          </Link>
        </li>
        <li className="nav-item active">
          <Link className="nav-link" to="/perguntas">
            <FaQuestion className="fas fa-fw fa-tachometer-alt mr-3" />
            <span>Perguntas</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
