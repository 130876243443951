import React from 'react';
import { Link } from 'react-router-dom';

const AccountSideBar: React.FC = () => {
  return (
    <div className="col-12 col-md-3">
      <div className="card card-bleed border-bottom border-bottom-md-0 shadow-light-lg">
        <div className="collapse d-md-block" id="sidenavCollapse">
          <div className="card-body">
            <h6 className="font-weight-bold text-uppercase mb-3">Meu perfil</h6>

            <ul className="card-list list text-gray-700 mb-6">
              <li className="list-item active">
                <Link className="list-link text-reset" to="/profile/me">
                  Geral
                </Link>
              </li>
              {/* <li className="list-item"> */}
              {/*  <Link className="list-link text-reset" to="/profile/experience"> */}
              {/*    Experiência */}
              {/*  </Link> */}
              {/* </li> */}
              {/* <li className="list-item"> */}
              {/*  <Link className="list-link text-reset" to="/profile/skills"> */}
              {/*    Habilidades */}
              {/*  </Link> */}
              {/* </li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSideBar;
