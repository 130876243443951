import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './route';

import SignIn from '../pages/SignIn';
// import SignUp from '../pages/SignUp';
import ForgotPassword from '../pages/ForgotPassword';
import Jobs from '../pages/Jobs';
import Questions from '../pages/Questions';
import JobDetail from '../pages/JobDetail';
import Profile from '../pages/Profile';
import AppliedUserDetail from '../pages/AppliedUserDetail';
import CreateJob from '../pages/CreateJob';
import UpdateJob from '../pages/UpdateJob';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    {/* <Route path="/criar-conta" component={SignUp} /> */}
    <Route path="/esqueci-minha-senha" component={ForgotPassword} />
    <Route path="/vagas" component={Jobs} isPrivate />
    <Route path="/perguntas" component={Questions} isPrivate />
    <Route path="/vaga/:job" component={JobDetail} isPrivate />
    <Route path="/perfil" component={Profile} isPrivate />
    <Route path="/nova-vaga" component={CreateJob} isPrivate />
    <Route path="/candidato/:user" component={AppliedUserDetail} isPrivate />
    <Route path="/editar-vaga/:id" component={UpdateJob} isPrivate />
  </Switch>
);

export default Routes;
