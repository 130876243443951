import React, { useRef, useCallback } from 'react';

import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useHistory, Link } from 'react-router-dom';
import getValidationErrors from '../../utils/getValidationErrors';

import { useToast } from '../../hooks/toast';

import Input from '../../components/Input';
import api from '../../services/api';

interface PasswordFormData {
  email: string;
  password: string;
}

const ForgotPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: PasswordFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('E-mail inválido'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/forgot', data);

        history.push('/');

        addToast({
          type: 'success',
          title: 'Cheque seu e-mail',
          description: 'Enviamos instruções de recuperação para você',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        } else {
          addToast({
            type: 'error',
            title: 'E-mail inválido',
            description: 'Esse e-mail não está associado à nenhum usuário',
          });
        }
      }
    },
    [history, addToast],
  );

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-12 col-md-9">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-lg-6 d-none d-lg-block bg-login-image" />
                <div className="col-lg-6">
                  <div className="p-5">
                    <div className="text-center">
                      <h1 className="h4 text-gray-900 mb-4">
                        Esqueci minha senha
                      </h1>
                    </div>
                    <Form
                      className="user"
                      ref={formRef}
                      onSubmit={handleSubmit}
                    >
                      <div className="form-group">
                        <Input
                          name="email"
                          type="email"
                          className="form-control form-control-user"
                          placeholder="E-mail"
                        />
                      </div>

                      <button
                        type="submit"
                        className="btn btn-primary btn-user btn-block"
                      >
                        Recuperar
                      </button>
                      <hr />
                    </Form>
                    <div className="text-center">
                      <Link className="small" to="/">
                        Voltar
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
