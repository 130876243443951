import React, { useRef, useCallback } from 'react';

import { FiCheckCircle } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Form } from './styles';
import Modal from '../Modal';
import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../Input';

interface Question {
  id: string;
  title: string;
}

interface ICreateQuestion {
  title: string;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  handleUpdateQuestion: (question: Omit<Question, 'id'>) => void;
  editingQuestion: Question;
}

const ModalEditQuestion: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  handleUpdateQuestion,
  editingQuestion,
}) => {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: ICreateQuestion) => {
      try {
        const schema = Yup.object().shape({
          title: Yup.string().required('Pergunta obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setIsOpen();

        handleUpdateQuestion(data);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
      }
    },
    [handleUpdateQuestion, setIsOpen],
  );

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Form ref={formRef} initialData={editingQuestion} onSubmit={handleSubmit}>
        <h1>Editar pergunta</h1>
        <div className="pl-lg-4">
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <p className="form-control-label">Pergunta</p>
                <Input name="title" className="form-control" />
              </div>
            </div>
          </div>
          <button className="btn   btn-block" type="submit">
            Salvar
            <div className="">
              <FiCheckCircle size={24} />
            </div>
          </button>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalEditQuestion;
