import React, { useCallback, useEffect, useState } from 'react';

import { FiPlusCircle } from 'react-icons/fi';
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/Navbar';
import api from '../../services/api';
import ModalAddQuestion from '../../components/ModalAddQuestion';
import ModalEditQuestion from '../../components/ModalEditQuestion';

interface Question {
  id: string;
  title: string;
}

interface ICreateQuestion {
  title: string;
}
const Questions: React.FC = () => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editingQuestion, setEditingQuestion] = useState<Question>(
    {} as Question,
  );

  useEffect(() => {
    api.get('/questions').then(response => {
      setQuestions(response.data);
    });
  }, []);

  async function handleAddQuestion(
    newQuestion: ICreateQuestion,
  ): Promise<void> {
    const response = await api.post('/questions', newQuestion);

    const newQuestionRegistered = response.data;

    setQuestions([...questions, newQuestionRegistered]);
  }

  const deleteQuestion = useCallback(
    async question => {
      await api.delete(`/questions/${question.id}`);

      setQuestions(
        questions.filter(eachQuestion => eachQuestion.id !== question.id),
      );
    },
    [questions],
  );

  async function handleUpdateQuestion(
    newQuestion: Omit<Question, 'id'>,
  ): Promise<void> {
    try {
      const currentListUsers = questions.map(currentUser => {
        if (currentUser.id !== editingQuestion.id) {
          return currentUser;
        }
        return {
          ...newQuestion,
          id: editingQuestion.id,
        };
      });

      const { title } = newQuestion;

      await api.put(`/questions/${editingQuestion.id}`, {
        title,
      });

      setQuestions(currentListUsers);
    } catch (err) {
      console.log(err);
    }
  }

  function toggleModel(): void {
    setModalOpen(!modalOpen);
  }

  function toggleEditModal(): void {
    setEditModalOpen(!editModalOpen);
  }

  function handleEditQuestion(question: Question): void {
    setEditingQuestion(question);
    toggleEditModal();
  }

  return (
    <>
      <ModalAddQuestion
        isOpen={modalOpen}
        setIsOpen={toggleModel}
        handleAddQuestion={handleAddQuestion}
      />
      <ModalEditQuestion
        isOpen={editModalOpen}
        setIsOpen={toggleEditModal}
        handleUpdateQuestion={handleUpdateQuestion}
        editingQuestion={editingQuestion}
      />
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <Navbar />
          <div id="content">
            <div className="container-fluid">
              {!modalOpen && !editModalOpen && (
                <>
                  <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Perguntas</h1>
                    <button
                      type="button"
                      onClick={() => setModalOpen(true)}
                      className="d-none d-sm-inline-block btn btn-sm btn-primary btn-success shadow-sm p-2"
                    >
                      <FiPlusCircle className="fas fa-download fa-sm mr-2" />
                      Nova pergunta
                    </button>
                  </div>
                  <p className="mb-4">Veja todas as perguntas cadastradas</p>
                  <div className="card card-bleed shadow-light-lg">
                    <div className="card-header">
                      <h4 className="mb-0">Perguntas cadastradas</h4>
                    </div>
                    <div className="card-body">
                      <div className="list-group list-group-flush">
                        {questions.map(question => (
                          <div className="list-group-item">
                            <div className="row align-items-center">
                              <div className="col">
                                <p className="mb-0">{question.title}</p>
                              </div>
                              <div className="col-auto">
                                <button
                                  type="button"
                                  className="badge btn rounded-pill bg-danger-soft"
                                  onClick={() => handleEditQuestion(question)}
                                >
                                  <span className="h6 text-uppercase fw-bold">
                                    Editar
                                  </span>
                                </button>
                              </div>
                              <div className="col-auto">
                                <button
                                  type="button"
                                  className="badge btn rounded-pill bg-danger-soft"
                                  onClick={() => deleteQuestion(question)}
                                >
                                  <span className="h6 text-uppercase fw-bold">
                                    Excluir
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Questions;
