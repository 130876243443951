import React, { useEffect, useMemo, useRef, useState } from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { useHistory, useRouteMatch } from 'react-router-dom';
import { FormHandles } from '@unform/core';
// eslint-disable-next-line import/extensions
import md from '../../utils/mdRenderer';
import { useToast } from '../../hooks/toast';

import SideBar from '../../components/Sidebar';

import { Form } from './styles';
import api from '../../services/api';
import Input from '../../components/Input';

interface IJob {
  id: string;
  name: string;
  salary: string;
  startTime: string;
  endTime: string;
  short_description: string;
  description: string;
}

interface IParams {
  id: string;
}

const UpdateJob: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { params } = useRouteMatch<IParams>();
  const [job, setJob] = useState<IJob>();
  const html = useMemo(() => md.render(job?.description || ''), [job]);
  const [content, setContent] = useState<string>(html);
  console.log(job);
  const { addToast } = useToast();

  useEffect(() => {
    api.get(`/jobs/${params.id}`).then(response => {
      setJob(response.data);
    });
  }, [params.id]);

  async function handleSubmit(post: Omit<IJob, 'id'>): Promise<void> {
    try {
      const { name, salary, startTime, endTime, short_description } = post;

      const data = new FormData();

      data.append('name', name);
      data.append('salary', salary);
      data.append('startTime', startTime);
      data.append('endTime', endTime);
      data.append('short_description', short_description);
      if (content) {
        data.append('description', content);
      } else {
        data.append('description', html);
      }

      await api.put(`/jobs/${params.id}`, data);

      addToast({
        type: 'success',
        title: 'Vaga criada com sucesso',
      });

      history.push('/');
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro na criação da vaga',
        description: 'Ocorreu um erro ao criar a vaga',
      });
    }
  }

  return (
    <div id="wrapper">
      <SideBar />
      <div className="main-content" id="panel">
        <div className="container-fluid mt--6">
          <div className="row justify-content-center">
            <div className=" col ">
              <div className="card">
                <div className="card-header bg-transparent">
                  <h3 className="mb-0">Editar vaga</h3>
                </div>
                {job && (
                  <div className="card-body">
                    <div
                      className="row icon-examples"
                      style={{ justifyContent: 'space-between' }}
                    >
                      <Form
                        ref={formRef}
                        initialData={job}
                        onSubmit={handleSubmit}
                      >
                        <h1>Editar Vaga</h1>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <p className="form-control-label">Nome</p>
                              <Input
                                name="name"
                                className="form-control"
                                containerStyle={{ color: '#000' }}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <p className="form-control-label">Salário</p>
                              <Input
                                name="salary"
                                className="form-control"
                                containerStyle={{ color: '#000' }}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <p className="form-control-label">
                                Início do expediente
                              </p>
                              <Input
                                name="startTime"
                                className="form-control form-control-user"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <p className="form-control-label">
                                Fim do expediente
                              </p>
                              <Input
                                name="endTime"
                                className="form-control form-control-user"
                              />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <p className="form-control-label">
                                Descrição curta
                              </p>
                              <Input
                                name="short_description"
                                className="form-control form-control-user"
                              />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <p className="form-control-label">Descrição</p>
                              <ReactQuill
                                onChange={setContent}
                                defaultValue={html}
                                theme="snow"
                                style={{ color: '#000' }}
                              />
                            </div>
                          </div>

                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Salvar
                          </button>
                        </div>
                      </Form>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateJob;
