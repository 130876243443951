import React, { useRef, useCallback } from 'react';

import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useHistory, Link } from 'react-router-dom';
import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import Input from '../../components/Input';

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const { signIn } = useAuth();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('E-mail inválido'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { email, password } = data;

        console.log(email.endsWith('@automacaocuritiba.com'));

        if (
          !email.endsWith('@automacaocuritiba.com') &&
          !email.endsWith('@automacaocuritiba.com.br')
        ) {
          addToast({
            type: 'error',
            title: 'Sem permissão',
            description: 'Para acessar sua conta entre pelo seu aplicativo',
          });

          return;
        }

        await signIn({
          email,
          password,
        });

        history.push('/vagas');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
        if (err.response.status === 400) {
          addToast({
            type: 'error',
            title: 'Conta não confirmada',
            description: 'Você precisa confirmar sua conta',
          });
        } else {
          addToast({
            type: 'error',
            title: 'Erro na autenticação',
            description:
              'Ocorreu um erro ao fazer login, cheque as suas credenciais',
          });
        }
      }
    },
    [signIn, history, addToast],
  );

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-12 col-md-9">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-lg-6 d-none d-lg-block bg-login-image" />
                <div className="col-lg-6">
                  <div className="p-5">
                    <div className="text-center">
                      <h1 className="h4 text-gray-900 mb-4">
                        Bem vindo de volta!
                      </h1>
                    </div>
                    <Form
                      className="user"
                      ref={formRef}
                      onSubmit={handleSubmit}
                    >
                      <div className="form-group">
                        <Input
                          name="email"
                          type="email"
                          className="form-control form-control-user"
                          id="exampleInputEmail"
                          placeholder="E-mail"
                        />
                      </div>
                      <div className="form-group">
                        <Input
                          type="password"
                          name="password"
                          className="form-control form-control-user"
                          id="exampleInputPassword"
                          placeholder="Senha"
                        />
                      </div>

                      <button
                        type="submit"
                        className="btn btn-primary btn-user btn-block"
                      >
                        Login
                      </button>
                      <hr />
                    </Form>
                    <div className="text-center">
                      <Link className="small" to="/esqueci-minha-senha">
                        Esqueceu a senha?
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
