import React, { useRef, useCallback } from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import '../../assets/css/theme.css';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import 'react-quill/dist/quill.snow.css';
import AccountHeader from '../../components/AccountHeader';
import AccountSideBar from '../../components/AccountSideBar';
import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import Input from '../../components/Input';

interface ProfileData {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
}

const Profile: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { user, updateUser } = useAuth();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: ProfileData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('E-mail inválido'),
          password: Yup.string(),
          password_confirmation: Yup.string()
            .when('password', {
              is: val => !!val.length,
              then: Yup.string().required(),
              otherwise: Yup.string(),
            })
            .oneOf([Yup.ref('password')], 'Confirmação incorreta'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { name, email, password } = data;

        const formData = {
          name,
          email,
          ...(password
            ? {
                password,
              }
            : {}),
        };

        const response = await api.put('/profile', formData);

        updateUser(response.data);

        addToast({
          type: 'success',
          title: 'Perfil atualizado',
          description: 'Suas informações foram atualizadas com sucesso',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
        addToast({
          type: 'error',
          title: 'Erro ao atualizar perfil',
          description: 'Cheque suas credenciais e tente novamente',
        });
      }
    },
    [addToast, updateUser],
  );

  return (
    <>
      <AccountHeader title="Meu perfil" />
      <main className="pb-8 pb-md-11 mt-md-n6">
        <div className="container-md">
          <div className="row">
            <AccountSideBar />
            <div className="col-12 col-md-9" data-aos="fade-up">
              <div className="card card-bleed shadow-light-lg mb-6  ">
                <div className="card-header">
                  <h4 className="mb-0">Informações basicas</h4>
                </div>
                <div className="card-body">
                  <Form
                    onSubmit={handleSubmit}
                    ref={formRef}
                    initialData={{
                      name: user.name,
                      email: user.email,
                    }}
                  >
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label htmlFor="name">Nome</label>
                          <Input
                            name="name"
                            id="name"
                            className="form-control"
                            type="text"
                            placeholder={user.name}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label htmlFor="email">E-mail</label>
                          <Input
                            name="email"
                            id="email"
                            type="email"
                            className="form-control"
                            placeholder={user.email}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label htmlFor="email">Nova senha</label>
                          <Input
                            name="password"
                            id="email"
                            className="form-control"
                            type="password"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label htmlFor="email">Confirmar senha</label>
                          <Input
                            name="password_confirmation"
                            id="email"
                            className="form-control"
                            type="password"
                          />
                        </div>
                      </div>
                      <div className="col-12 ">
                        <button
                          className="btn btn-block btn-primary"
                          type="submit"
                        >
                          Confirmar alterações
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Profile;
