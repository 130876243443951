import React, { useRef, useState } from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { useToast } from '../../hooks/toast';

import SideBar from '../../components/Sidebar';

import { Form } from './styles';
import api from '../../services/api';
import Input from '../../components/Input';

interface IPost {
  id: string;
  name: string;
  salary: string;
  startTime: string;
  endTime: string;
  short_description: string;
}

const CreateJob: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [content, setContent] = useState<string>('');

  const { addToast } = useToast();

  async function handleSubmit(post: Omit<IPost, 'id'>): Promise<void> {
    try {
      const { name, salary, startTime, endTime, short_description } = post;

      const data = new FormData();

      data.append('name', name);
      data.append('salary', salary);
      data.append('startTime', startTime);
      data.append('endTime', endTime);
      data.append('short_description', short_description);
      data.append('description', content);

      await api.post('/jobs', data);

      addToast({
        type: 'success',
        title: 'Vaga criada com sucesso',
      });

      history.push('/');
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro na criação da vaga',
        description: 'Ocorreu um erro ao criar a vaga',
      });
    }
  }

  return (
    <div id="wrapper">
      <SideBar />
      <div className="main-content" id="panel">
        <div className="container-fluid mt--6">
          <div className="row justify-content-center">
            <div className=" col ">
              <div className="card">
                <div className="card-header bg-transparent">
                  <h3 className="mb-0">Nova vaga</h3>
                </div>
                <div className="card-body">
                  <div
                    className="row icon-examples"
                    style={{ justifyContent: 'space-between' }}
                  >
                    <Form ref={formRef} onSubmit={handleSubmit}>
                      <h1>Nova Vaga</h1>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <p className="form-control-label">Nome</p>
                            <Input
                              name="name"
                              className="form-control"
                              containerStyle={{ color: '#000' }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <p className="form-control-label">Salário</p>
                            <Input
                              name="salary"
                              className="form-control"
                              containerStyle={{ color: '#000' }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <p className="form-control-label">
                              Início do expediente
                            </p>
                            <Input
                              name="startTime"
                              className="form-control form-control-user"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <p className="form-control-label">
                              Fim do expediente
                            </p>
                            <Input
                              name="endTime"
                              className="form-control form-control-user"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <p className="form-control-label">
                              Descrição curta
                            </p>
                            <Input
                              name="short_description"
                              className="form-control form-control-user"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <p className="form-control-label">Descrição</p>
                            <ReactQuill
                              onChange={setContent}
                              theme="snow"
                              placeholder="Escreva sobre a vaga...."
                              style={{ color: '#000' }}
                            />
                          </div>
                        </div>

                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Criar vaga
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateJob;
