import React, { useState, useEffect } from 'react';

import { Link, useRouteMatch } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import ReactLoading from 'react-loading';
import api from '../../services/api';
import AccountHeader from '../../components/AccountHeader';
import Avatar from '../../assets/img/illustrations/defaultAvatar.png';
import { useToast } from '../../hooks/toast';
import ModalSendMessage from '../../components/ModalSendMessage';

interface IParams {
  user: string;
}

interface IUser {
  id: string;
  name: string;
  email: string;
  about: string;
  linkedin: string;
  NO: string;
  SO: number;
  NE: number;
  SE: number;
  avatar_url: string;
}

interface Message {
  title: string;
  content: string;
  user_id: string;
}

interface Question {
  id: string;
  title: string;
  pivot: {
    answer: string;
  };
}

interface ISendMessage {
  title: string;
  content: string;
  user_id: string;
}

const AppliedUserDetail: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<IUser>();
  const [questions, setQuestions] = useState<Question[]>([]);
  const { addToast } = useToast();
  const { params } = useRouteMatch<IParams>();
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    api.get(`/user/${params.user}`).then(response => {
      setUser(response.data);
    });
  }, [params.user]);

  useEffect(() => {
    api.get(`/answers/questions/${user?.id}`).then(response => {
      setQuestions(response.data);
    });
  }, [params.user, user]);

  async function handleSendMessage(newMessage: ISendMessage): Promise<void> {
    try {
      if (!user) {
        throw new Error();
      }

      await api.post('/messages', { ...newMessage, user_id: user.id });

      addToast({
        type: 'success',
        title: 'Mensagem enviada',
      });
    } catch (err) {
      console.log(err);
      addToast({
        type: 'error',
        title: 'Erro no envio da mensagem',
      });
    }
  }

  function toggleModel(): void {
    setModalOpen(!modalOpen);
  }

  setTimeout(() => setLoading(false), 700);

  return (
    <>
      {loading && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '500px',
          }}
        >
          <ReactLoading type="bubbles" color="#335eea" width={100} />
        </div>
      )}
      <ModalSendMessage
        isOpen={modalOpen}
        setIsOpen={toggleModel}
        handleSendMessage={handleSendMessage}
      />
      {!loading && user && !modalOpen && (
        <>
          <AccountHeader title={user.name} />

          <main className="pb-8 pb-md-11 mt-md-n6">
            <div className="container-md">
              <div className="row">
                <div className="col-12 col-md-3">
                  <div className="card card-bleed border-bottom border-bottom-md-0 shadow-light-lg">
                    <div className="collapse d-md-block" id="sidenavCollapse">
                      <div className="card-body">
                        <h6 className="font-weight-bold text-uppercase mb-3">
                          Usuário
                        </h6>

                        <ul className="card-list list text-gray-700 mb-6">
                          <li className="list-item ">
                            <Link
                              className="list-link text-reset active"
                              to={`/dashboard/applied-user/${params.user}`}
                            >
                              Geral
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-9" data-aos="fade-up">
                  <div className="card card-bleed shadow-light-lg mb-6">
                    <div className="card-header">
                      <h4 className="mb-0">Informações basicas</h4>
                    </div>
                    <div className="list-group-item">
                      <div className="row align-items-center">
                        <div className="col-auto mr-4">
                          <div className="avatar avatar-xxl">
                            {user.avatar_url ? (
                              <img
                                className="avatar-img rounded-circle"
                                src={user.avatar_url}
                                alt="..."
                              />
                            ) : (
                              <img
                                className="avatar-img rounded-circle"
                                src={Avatar}
                                alt="..."
                              />
                            )}
                          </div>
                        </div>
                        <div className="col ml-n5 ">
                          <p className="mb-0">{user.name}</p>

                          <small className="text-gray-700">{user.email}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 flex-row">
                    <div className="card card-bleed shadow-light-lg mb-8 ">
                      <div className="card-body">
                        <div className="list-group list-group-flush">
                          <div className="list-group-item">
                            <div className="row align-items-center">
                              <div className="col">
                                <p className="mb-0">NO</p>
                              </div>
                              <div className="col-auto">
                                <div className="form-check form-switch">
                                  <span className="badge rounded-pill bg-danger-soft">
                                    <span className="h4 text-uppercase fw-bold">
                                      {Number(user.NO)}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card card-bleed shadow-light-lg mb-8">
                      <div className="card-body">
                        <div className="list-group list-group-flush">
                          <div className="list-group-item">
                            <div className="row align-items-center">
                              <div className="col">
                                <p className="mb-0">NE</p>
                              </div>
                              <div className="col-auto">
                                <div className="form-check form-switch">
                                  <span className="badge rounded-pill bg-danger-soft">
                                    <span className="h4 text-uppercase fw-bold">
                                      {Number(user.NE)}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card card-bleed shadow-light-lg mb-8">
                      <div className="card-body">
                        <div className="list-group list-group-flush">
                          <div className="list-group-item">
                            <div className="row align-items-center">
                              <div className="col">
                                <p className="mb-0">SO</p>
                              </div>
                              <div className="col-auto">
                                <div className="form-check form-switch">
                                  <span className="badge rounded-pill bg-danger-soft">
                                    <span className="h4 text-uppercase fw-bold">
                                      {Number(user.SO)}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card card-bleed shadow-light-lg mb-8">
                      <div className="card-body">
                        <div className="list-group list-group-flush">
                          <div className="list-group-item">
                            <div className="row align-items-center">
                              <div className="col">
                                <p className="mb-0">SE</p>
                              </div>
                              <div className="col-auto">
                                <div className="form-check form-switch">
                                  <span className="badge rounded-pill bg-danger-soft">
                                    <span className="h4 text-uppercase fw-bold">
                                      {Number(user.SE)}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card card-bleed shadow-light-lg mb-8">
                    <div className="card-header">
                      <h4 className="mb-0">Dados adicionais</h4>
                    </div>
                    <div className="card-body">
                      <div className="list-group list-group-flush">
                        <div className="list-group-item">
                          <div className="row align-items-center">
                            <div className="col">
                              <p className="mb-0">Linkedin</p>

                              <small className="text-gray-700">
                                Acesse meu perfil no linkedin
                              </small>
                            </div>
                            <div className="col-auto">
                              <div className="form-check form-switch">
                                {user.linkedin ? (
                                  <a
                                    href={user.linkedin}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="mb-0"
                                  >
                                    {user.linkedin}
                                  </a>
                                ) : (
                                  <span className="badge rounded-pill bg-danger-soft">
                                    <span className="h6 text-uppercase fw-bold">
                                      Não informado
                                    </span>
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="list-group-item">
                          <div className="row align-items-center">
                            <div className="col">
                              <p className="mb-0">Enviar mensagem</p>

                              <small className="text-gray-700">
                                Envie uma mensagem diretamente para
                                {user.name}
                              </small>
                            </div>
                            <div className="col-auto">
                              <div className="form-check form-switch">
                                <button
                                  type="button"
                                  style={{ border: 'none' }}
                                  className="badge rounded-pill bg-success-soft"
                                  onClick={() => setModalOpen(true)}
                                >
                                  <span className="h6 text-uppercase fw-bold">
                                    Enviar
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card card-bleed shadow-light-lg mb-8">
                    <div className="card-header">
                      <h4 className="mb-0">Perguntas</h4>
                    </div>
                    <div className="card-body">
                      <div className="list-group list-group-flush">
                        {questions.map(question => (
                          <div className="list-group-item">
                            <div className="row align-items-center">
                              <div className="col">
                                <p className="mb-0">{question.title}</p>

                                <small className="text-gray-700">
                                  {question.pivot.answer}
                                </small>
                              </div>
                              <div className="col-auto">
                                {/* <div className="form-check form-switch"> */}
                                {/*  {user.linkedin ? ( */}
                                {/*    <a */}
                                {/*      href={user.linkedin} */}
                                {/*      target="_blank" */}
                                {/*      className="mb-0" */}
                                {/*    > */}
                                {/*      {user.linkedin} */}
                                {/*    </a> */}
                                {/*  ) : ( */}
                                {/*    <span className="badge rounded-pill bg-danger-soft"> */}
                                {/*      <span className="h6 text-uppercase fw-bold"> */}
                                {/*        Não informado */}
                                {/*      </span> */}
                                {/*    </span> */}
                                {/*  )} */}
                                {/* </div> */}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
      )}
    </>
  );
};

export default AppliedUserDetail;
