import React, { useCallback } from 'react';

import { useHistory } from 'react-router-dom';

interface AccountHeaderProps {
  title: string;
}

const AccountHeader: React.FC<AccountHeaderProps> = ({ title }) => {
  const history = useHistory();

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <header className="bg-dark pt-9 pb-11 d-none d-md-block">
      <div className="container-md">
        <div className="row align-items-center">
          <div className="col">
            <h1 className="font-weight-bold text-white mb-2">{title}</h1>
          </div>
          <div className="col-auto">
            <button
              type="button"
              onClick={handleGoBack}
              className="btn btn-sm btn-gray-300-20"
            >
              Voltar
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default AccountHeader;
